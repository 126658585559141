import React from 'react';
import PropTypes from 'prop-types';

import './CustomRadioButton.less';

export function CustomRadioButton({ children, onClick, active, label }) {
    return (
        <div onClick={onClick} className="optionContainer">
            <div className="radioButtonOuter">
                <div className={active ? 'activeRadio' : ''} />
                <input onChange={onClick} type="radio" name="radio" aria-label={label}/>
            </div>
            {children}
        </div>
    );
}

CustomRadioButton.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    active: PropTypes.bool
};
