import React from 'react';
import PropTypes from 'prop-types';

const MinicartIcon = props => {
    const { color, width, height, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 19.36 20.36"
            width={width}
            height={height}
        >
            <path
                fill="none"
                stroke={color}
                strokeWidth="1.361"
                d="M0.68 4.9H18.68V19.68H0.68z"
            ></path>
            <path
                fill="none"
                stroke={color}
                strokeWidth="1.361"
                d="M5.68 7.01V4.38a3.86 3.86 0 014-3.7 3.86 3.86 0 014 3.7v2.63"
            ></path>
        </svg>
    );
};

MinicartIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MinicartIcon.defaultProps = {
    color: 'currentColor',
    size: '24',
    width: '20',
    height: '20'
};

export default MinicartIcon;
