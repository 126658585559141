import React, { useContext, useEffect } from 'react';
import { refreshPlacements } from '@corratech/klarna-promotions/utils/refreshPlacements';
import { hasGiftCardProduct } from './utils/hasGiftCardProduct';

let CartStore, useGlobalOptions, LoaderStore;
if (process.env.STORYBOOK_IN_STORYBOOK) {
    const allContext = require('@corratech/checkout/SummaryBlock/MockCartContext');
    CartStore = allContext.CartStore;
    useGlobalOptions = allContext.useGlobalOptions;
    LoaderStore = allContext.LoaderStore;
} else {
    const allContext = require('@corratech/context-provider');
    CartStore = allContext.CartStore;
    useGlobalOptions = allContext.useGlobalOptions;
    LoaderStore = allContext.LoaderStore;
}

export const KlarnaPromotionBadge = props => {
    const globalOptions = useGlobalOptions();
    const { cartState } = useContext(CartStore);

    const config = {
        enabled: globalOptions.storeConfig.klarna_enabled,
        enabledStore: globalOptions.storeConfig.klarna_enabled_in_stores,
        storeCode: globalOptions.storeConfig.code
    };
    const storeArray =
        config.enabledStore !== '' ? config.enabledStore.split(',') : [];

    useEffect(() => {
        if (cartState.cart) {
            if (
                !hasGiftCardProduct(cartState) &&
                storeArray.includes(config.storeCode)
            ) {
                refreshPlacements();
            }
        }
    }, [cartState.cart]);

    const getGrandTotal = () => {
        return Math.round(cartState.cart.prices.grand_total.value * 100);
    };

    return (
        <div className={'klarna-promotion'}>
            <klarna-placement
                data-key="credit-promotion-auto-size"
                data-locale={window.STORE_CODE == 'uk' ? 'en-GB' : 'en-US'}
                data-preloaded="true"
                data-purchase-amount={getGrandTotal()}
            ></klarna-placement>
        </div>
    );
};
