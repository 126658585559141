import React from 'react';
import { Button } from 'react-bootstrap';
import './AddressBlock.less';

export function AddressBlock({ address, onClick, action }) {
    if (address)
        return (
            <div className={'shipping-address-container'}>
                <div className={'shipping-address-block'}>
                    <address className={'entered-shipping-address'} data-cs-mask>
                        <span>{`${address.firstname} ${address.lastname}`}</span>
                        <span>{address.company || null}</span>
                        <span>
                            {address.street.map((street, idx) => (
                                <span key={idx}>{street}</span>
                            ))}
                        </span>
                        <span>
                            {[
                                address.city,
                                address.region && (address.region.label || address.region.region),
                                address.postcode
                            ]
                                .filter(Boolean)
                                .join(', ')}
                        </span>
                        <span>
                            {address.country
                                ? address.country.label
                                : address.country_id}
                        </span>
                        <span>
                            <a href={`tel:${address.telephone}`}>
                                {address.telephone}
                            </a>
                        </span>
                    </address>
                </div>
                <div className={'step-btn-block shipping-address-btn-block'}>
                    {action && (
                        <Button variant="link" onClick={onClick}>
                            {action}
                        </Button>
                    )}
                </div>
            </div>
        );
    else return null;
}
