import { Overlay } from '@corratech/overlay';
import { useToasts } from '@magento/peregrine';
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { CloudOff as FiCloudOff, Wifi as FiWifi } from 'react-feather';
import { TagContextInit } from '@corratech/tag-manager';
import { SEOFallback } from '@corratech/seo';
import { OptionsStore } from '@corratech/context-provider';
import { Shell } from '../../Modules/Shell/Shell';
import RenderRoutes from './RenderRoutes';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
/**
 * "Shit-in-the-Dirt" workaround that is required for GTM scripting.
 * Expose React Router's useHistory to the window global object,
 * this may be used for GTM scripts to be able to use website navigation without page reload.
 *
 * const url = 'pro-collagen-marine-cream-spf-30.html';
 * if (window.ElemisApp && typeof window.ElemisApp.ReactUseHistory === 'function') {
 *     const history = window.ElemisApp.ReactUseHistory();
 *     if (history && typeof history.push === 'function') {
 *         history.push(url);
 *     } else {
 *         console.warn('History object or push function is not available');
 *     }
 * } else {
 *     console.warn('React Router history object is not available');
 * }
 */

const ExposeHistory = () => {
    const history = useHistory();

    useEffect(() => {
        window.ElemisApp = window.ElemisApp || {};
        window.ElemisApp.ReactUseHistory = () => history;
    }, [history]);

    return null;
};

import {
    GTMActionOverride,
    GTMMappingOverride,
    CustomPages
} from '../Tagmanager';

//These must be 'require' not import
require(`../../design/themes/elemis/theme.less`);
//import ToastContainer from '../ToastContainer';
import { ToastContainer } from '@corratech/toast-container';
import { ScrollToTop } from '@corratech/scroll-to-top';
import { useGTMTracking } from '@corratech/google-tag-manager';
const OnlineIcon = <FiWifi attrs={{ width: 18 }} />;
const OfflineIcon = <FiCloudOff attrs={{ width: 18 }} />;
import { MergeCartOnStopMakeCart } from './MergeCartOnStopMakeCart';

export const App = props => {
    const { markErrorHandled, renderError, unhandledErrors } = props;
    const [hasBeenOffline, setHasBeenOffline] = useState(false);
    const [isOnline, setIsOnline] = useState(false);
    const projectConfig = useContext(OptionsStore) || {};
    const storeConfig = projectConfig.storeConfig || {};

    const [, { addToast }] = useToasts();

    if (renderError) {
        return (
            <Fragment>
                <Shell
                    isMasked={true}
                    hasBeenOffline={hasBeenOffline}
                    isOnline={isOnline}
                />
                <ToastContainer />
            </Fragment>
        );
    }

    const logOnline = () => {
        setHasBeenOffline(false);
        setIsOnline(true);
    };

    const logOffline = () => {
        setHasBeenOffline(true);
        setIsOnline(false);
    };

    useEffect(() => {
        window.addEventListener('online', logOnline);
        window.addEventListener('offline', logOffline);
        return () => {
            window.removeEventListener('online', logOnline);
            window.removeEventListener('offline', logOffline);
        };
    }, []);

    useEffect(() => {
        if (hasBeenOffline) {
            addToast({
                type: 'error',
                icon: OfflineIcon,
                message: 'You are offline. Some features may be unavailable.',
                timeout: 3000
            });
        } else if (isOnline) {
            addToast({
                type: 'info',
                icon: OnlineIcon,
                message: 'You are online.',
                timeout: 3000
            });
        }
    }, [hasBeenOffline, isOnline]);

    return (
        <Fragment>
            <ExposeHistory />
            <TagContextInit
                dataLayerHandlers={[
                    useGTMTracking({
                        enablePageViewDataLayerReset: true,
                        dataLayerActionOverride: GTMActionOverride,
                        mapping: GTMMappingOverride,
                        gtmId: storeConfig.google_analytics_container_id,
                        gtmAuth: storeConfig.google_analytics_gtm_auth || '',
                        gtmPreview:
                            storeConfig.google_analytics_gtm_preview || ''
                    })
                ]}
                checkoutStepIdByName={{
                    shipping: 1,
                    payment: 2
                }}
                customPages={CustomPages}
            >
                <ScrollToTop>
                    <Shell hasBeenOffline={hasBeenOffline} isOnline={isOnline}>
                        <SEOFallback customPages={CustomPages} />
                        <RenderRoutes />
                        <Overlay />
                    </Shell>
                </ScrollToTop>
                <ToastContainer />
            </TagContextInit>
            <MergeCartOnStopMakeCart />
        </Fragment>
    );
};
