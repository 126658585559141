import React, { useEffect } from 'react';
import { PLPManager } from 'ModulesPath/Catalog/PLP/PLPManager';
import { TrackSearchContent } from 'ModulesPath/FacebookConversion/TrackSearchContent';
import ErrorView from 'ModulesPath/ErrorView/';
import { DebugBearMonitoring } from 'ModulesPath/DebugBear/DebugBearMonitoring';

const SearchResult = React.lazy(() =>
    import('ModulesPath/Catalog/SearchResult')
);

export const Search = props => {
    // Track FB search content
    var url = new URL(window.location.href);
    var searchQuery = url.searchParams.get('query');
    const callTrackSearchContent = TrackSearchContent();
    useEffect(() => {
        if (searchQuery) {
            callTrackSearchContent(searchQuery);
        }
    }, [props]);

    if (!searchQuery) {
        return <ErrorView notFound={true} />;
    }

    useEffect(() => {
        DebugBearMonitoring('Search');
    }, []);

    return (
        <PLPManager>
            <SearchResult {...props} />
        </PLPManager>
    );
};
