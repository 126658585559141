import gql from 'graphql-tag';

export const queryCustomerCart = gql`
    query CustomerCart {
        customerCart {
            id
            total_quantity
            is_virtual
            free_items {
                free_gifts {
                    sku
                    name
                    rule_id
                    max_qty
                    img
                    is_added
                    allowed_qty
                }
                free_samples {
                    sku
                    name
                    rule_id
                    max_qty
                    img
                    is_added
                    allowed_qty
                }
            }
            items {
                id
                itemsku
                exploding_kit
                product {
                    id
                    name
                    sku
                    stock_status
                    thumbnail {
                        label
                        url
                    }

                    price {
                        regularPrice {
                            amount {
                                currency
                                value
                            }
                        }
                    }
                    special_price
                    url_key
                    ... on ConfigurableProduct {
                        variants {
                            product {
                                id
                                sku
                            }
                        }
                    }
                }
                ... on ConfigurableCartItem {
                    configurable_options {
                        id
                        option_label
                        value_id
                        value_label
                    }
                }
                ... on GiftCardCartItem {
                    customizable_options {
                        label
                        values {
                            value
                        }
                    }
                    giftcard_options {
                        giftcard_amount {
                            value
                            currency
                        }
                        giftcard_sender_name
                        giftcard_recipient_name
                        giftcard_sender_email
                        giftcard_recipient_email
                        giftcard_message
                    }
                }
                ... on BundleCartItem {
                    bundle_options {
                        id
                        label
                        type
                        values {
                            id
                            label
                            price
                            quantity
                        }
                    }
                }
                prices {
                    price {
                        value
                    }
                    row_total_including_tax {
                        value
                        currency
                    }
                    row_total {
                        value
                        currency
                    }
                }
                quantity
            }
            prices {
                grand_total {
                    value
                    currency
                }
                applied_taxes {
                    label
                    amount {
                        value
                        currency
                    }
                }
                subtotal_including_tax {
                    value
                    currency
                }
                subtotal_excluding_tax {
                    value
                    currency
                }
                subtotal_with_discount_excluding_tax {
                    value
                    currency
                }
                discounts {
                    label
                    amount {
                        value
                    }
                }
            }
            applied_store_credit {
                applied_balance {
                    currency
                    value
                }
                current_balance {
                    currency
                    value
                }
                enabled
            }
            applied_gift_cards {
                applied_balance {
                    value
                    currency
                }
                code
                current_balance {
                    value
                    currency
                }
                expiration_date
            }
            applied_coupons {
                code
            }
            available_payment_methods {
                code
                title
            }
        }
    }
`;
