import React from 'react';
import PropTypes from 'prop-types';

const StoreIcon = props => {
    const { color, size, width, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15.4 17.6"
            width={width}
        >
            <circle
                cx="7.7"
                cy="7.47"
                r="1"
                fill="none"
                stroke={color}
                strokeWidth="1.4"
            ></circle>
            <path
                fill="none"
                stroke={color}
                strokeWidth="1.4"
                d="M7.7 16.7c4.69-3.85 7-7 7-9.36A6.82 6.82 0 007.7.7a6.82 6.82 0 00-7 6.64c0 2.36 2.31 5.51 7 9.36z"
            ></path>
        </svg>
    );
};

StoreIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

StoreIcon.defaultProps = {
    color: 'currentColor',
    size: '24',
    width: '15'
};

export default StoreIcon;
