import React from 'react';
import PropTypes from 'prop-types';

const Logo = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 839.81 233.2" role="img" aria-labelledby='logo'>
            <title id='logo'>Elemis logo</title>
            <path
                fill={color}
                fillRule="evenodd"
                d="M832.71 25.6V11.9c0-2.1-.3-3.7-2.8-4.7a88.48 88.48 0 00-35-7.2c-32.4 0-54.9 11.4-55.3 39.2-.8 46.1 75.5 39 75 67.5-.3 18.7-18.3 23.1-35.8 22.5a79.87 79.87 0 01-35.8-9.6c-2.4-1.2-3.5-1.3-3.6 2.2l-.1 15.4c0 2.5.9 3.7 2.6 4.6 11.3 5.6 27.9 8.3 39.4 8.3 35.6 0 58.5-12.7 58.5-43.2 0-49.3-75.7-43.8-75.3-68.8.3-15.5 17.6-18.5 31.1-18.4a78.94 78.94 0 0133.4 8c2.7 1.2 3.7.4 3.7-2.1zM499.91 5c-1.2-1.7-2.8-2.9-4.4-2.9h-12.2c-2.8 0-3.8 1-3.8 3.7v138.3c0 2.7 1 3.7 3.8 3.7h16c2.8 0 3.8-1 3.8-3.7V47.9l47.2 72.1c1.6 2.4 2.6 3.2 4.9 3.2s3.4-.8 5-3.2l47.2-72.1V144c0 2.7 1 3.7 3.8 3.7h16c2.8 0 3.8-1 3.8-3.7V5.8c0-2.7-1-3.7-3.8-3.7h-12.2c-1.6 0-3.2 1.2-4.4 2.9l-55.4 84.1zM42.61 91c13.1-.3 25.4-4.4 31-6.3 34.3-11.6 46.9-14.6 61.2-10.2.8.3 1.9.7 2.2.3s-.3-1.7-.8-2.5c-18.4-23.6-56.5-13.7-70.5-9.3-37.9 11.7-47.7 14.5-63.5 11-.9-.2-1.7-.6-2.1-.1s.4 1.9 1.1 2.7a40.62 40.62 0 0017.6 11.9v55.7c0 2.8.6 3.7 2.9 3.7h112.4c2.3 0 2.9-.6 2.9-2.9v-16c0-2.3-.6-2.9-2.9-2.9h-91.3V91zm.1-67.5h91.2c2.3 0 2.9-.6 2.9-2.9V4.9c0-2.3-.6-2.9-2.9-2.9H21.51c-2.3 0-2.9.6-2.9 3.5v59.2c10.4-.5 21.3-5.3 24.2-12.7zM342.91 91c13.1-.3 25.4-4.4 31-6.3 34.3-11.6 46.9-14.6 61.2-10.2.8.3 1.9.7 2.2.3s-.3-1.7-.8-2.5c-18.6-23.5-56.7-13.6-70.7-9.2-37.9 11.7-47.7 14.5-63.6 11-.9-.2-1.7-.6-2.1-.1s.4 1.9 1.1 2.7a40.62 40.62 0 0017.6 11.9v55.7c0 2.8.6 3.7 2.9 3.7h112.4c2.3 0 2.9-.6 2.9-2.9v-16c0-2.3-.6-2.9-2.9-2.9h-91.3V91zm.1-67.5h91.2c2.3 0 2.9-.6 2.9-2.9V4.9c0-2.3-.6-2.9-2.9-2.9l-112.4.1c-2.3 0-2.9.6-2.9 3.5v59.2c10.4-.5 21.2-5.3 24.1-12.7zM678.91 2c-2.8 0-3.7 1-3.7 3.7v138.5c0 2.7.9 3.7 3.7 3.7h17.1c2.8 0 3.7-1 3.7-3.7V5.8c0-2.7-1-3.7-3.7-3.7h-17.1zm-392 127.3v15.4c0 2.8-.5 3.2-3.4 3.2h-100.6c-2.8 0-3.7-.9-3.7-3.7V5.4c0-2.8.5-3.4 3.4-3.4h17.6c2.8 0 3.4.6 3.4 3.4v120.7h79.8c2.8 0 3.5.4 3.5 3.2z"
            ></path>
            <path
                fill={color}
                d="M408.01 192.8V232h-7.4l-19.9-24.2h-.1V232h-8.6v-39.2h7.4l20 24.3h.1v-24.3z"
            ></path>
            <path
                fill={color}
                fillRule="evenodd"
                d="M333.91 191.6c12.4 0 22.4 9.3 22.4 20.7 0 11.6-10 20.9-22.4 20.9s-22.1-9.2-22.1-20.9c0-11.4 9.7-20.7 22.1-20.7zm0 34.2a13.4 13.4 0 100-26.8 13.12 13.12 0 00-13.1 13.3 13.3 13.3 0 0013.1 13.5zM427.01 232v-39.2h18.4c7.2 0 11.9 2 15.4 5.6a19.92 19.92 0 015.3 14 20.33 20.33 0 01-5.6 14.5c-3.4 3.2-7.6 5.2-15 5.2h-18.5zm18.1-7.4c4.5 0 6.4-1 8.4-2.8a14.13 14.13 0 00.3-18.5c-2-2-4.1-3.1-8.7-3.1h-9.5v24.4z"
            ></path>
            <path
                fill={color}
                d="M574.81 192.8V232h-7.4l-19.9-24.2h-.1V232h-8.6v-39.2h7.4l20 24.3h.1v-24.3zM500.81 191.6c12.4 0 22.4 9.3 22.4 20.7 0 11.6-10 20.9-22.4 20.9s-22.1-9.2-22.1-20.9c0-11.4 9.7-20.7 22.1-20.7zm0 34.2a13.4 13.4 0 100-26.8 13.4 13.4 0 000 26.8zM278.61 192.8v31.8h23v7.4h-31.6v-39.2z"
            ></path>
        </svg>
    );
};

Logo.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Logo.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default Logo;
