import React from 'react';
import {useTranslation} from "react-i18next";
import './PaymentLoaderWrapper.less';

const PaymentLoaderWrapper = () => {
    const [t] = useTranslation();

    return (
        <div className="loading-wrapper">
            <div className="loading-inner">
                <div className={'loading-container'}>
                    <div className="loading-spinner">
                        <div className="loader-dots">
                            <div
                                style={{
                                    left: '30px',
                                    background: '#0f213a',
                                    animationDelay: '-0.67s'
                                }}
                            ></div>
                            <div
                                style={{
                                    left: '60px',
                                    background: '#4f758b',
                                    animationDelay: '-0.5025000000000001s'
                                }}
                            ></div>
                            <div
                                style={{
                                    left: '90px',
                                    background: '#5ab0bc',
                                    animationDelay: '-0.335s'
                                }}
                            ></div>
                            <div
                                style={{
                                    left: '120px',
                                    background: '#c8dbef',
                                    animationDelay: '-0.1675s'
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
                <p className="loading-title">
                    {t('We\'re completing your order, your skin will thank you for your patience!')}
                </p>
                <p className="loading-text-small">
                    {t('Do not refresh or exit the page')}
                </p>
            </div>
        </div>
    )
}

export default PaymentLoaderWrapper;