import React from 'react';
import style from './Price.less';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
let examineClasses = classNames.bind(style);

export const Price = ({
    product,
    inline,
    embroideryPrice,
    setProductPrice,
    isGroupedProduct
}) => {
    if (product.price_range) {
        var currencySymbol;
        //TODO: Use this switch-case to add international currency support
        switch (product.price_range.minimum_price.regular_price.currency) {
            case 'USD':
                currencySymbol = '$';
                break;
            case 'GBP':
                currencySymbol = '£';
                break;
            case 'EUR':
                currencySymbol = '€';
                break;
            default:
                currencySymbol = '$';
                break;
        }
        if (embroideryPrice) {
            const finalPrice = (
                parseFloat(
                    product.price_range.minimum_price.final_price.value
                ) + embroideryPrice
            ).toFixed(2);
            setProductPrice(parseFloat(finalPrice));
            return (
                <span className={'original-price'}>
                    {currencySymbol + finalPrice}
                </span>
            );
        } else if (isGroupedProduct) {
            const forLoopMinMax = items => {
                let priceRange = [];

                for (let i = 1; i < items.length; i++) {
                    let valueMin =
                        items[i].product.price_range.minimum_price.regular_price
                            .value;
                    let valueMax =
                        items[i].product.price_range.maximum_price.regular_price
                            .value;
                    priceRange.push(valueMin, valueMax);
                }

                return [Math.min(...priceRange), Math.max(...priceRange)];
            };

            const [min, max] = forLoopMinMax(product.items);

            return (
                <span className={'original-price grouped-price'}>
                    {currencySymbol + min.toFixed(2)}
                    {'-'}
                    {max.toFixed(2)}
                </span>
            );
        } else {
            if (product.price_range.minimum_price.discount.amount_off) {
                const priceClasses = examineClasses('prices', {
                    inline: inline
                });
                return (
                    <div className={priceClasses}>
                        <span className={'sale-price'}>
                            {currencySymbol +
                                parseFloat(
                                    product.price_range.minimum_price
                                        .final_price.value
                                ).toFixed(2)}
                        </span>

                        <span className={'old-price'}>
                            {currencySymbol +
                                parseFloat(
                                    product.price_range.minimum_price
                                        .regular_price.value
                                ).toFixed(2)}
                        </span>
                    </div>
                );
            } else {
                return (
                    <span className={'original-price'}>
                        {currencySymbol +
                            product.price_range.minimum_price.final_price.value.toFixed(
                                2
                            )}
                    </span>
                );
            }
        }
    } else {
        return '';
    }
};

Price.propTypes = {
    product: PropTypes.object.isRequired,
    inline: PropTypes.bool
};
