import React from 'react';
import PropTypes from 'prop-types';

const EmptyCartIcon = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 193.94 245.7"
        >
            <g>
                <path
                    fill="#0f213a"
                    fillRule="evenodd"
                    d="M44.44 234.7L32.33 100.43h37.36v16.09a3.85 3.85 0 107.7.08v-16.17h54.74v16.09a3.83 3.83 0 002.21 3.6 3.88 3.88 0 005.49-3.52v-16.17h29.39l14 125.61zm-15-11.52l.29-66.46 6.42 72.68zm-6.88-67L21.77 222l-9.92 2.16zM74 76.8c-2.15 4.25-3.64 11-4.14 15.93H68.6c.71-5.08 2.63-11.88 5.44-15.93zm25-11.7c14.57 1.61 21.42 13.12 23.46 27.63H77.68c1.88-15.26 7.61-25.17 21.41-27.63zm32.76 27.63h-1.52c-.75-5.64-1.28-11-4.07-15.91 4 4.68 4.76 9.47 5.59 15.91zm44.63 3.41a3.84 3.84 0 00-3.82-3.41h-33c-2-20.12-14.16-35.87-34.81-35.87a39.46 39.46 0 00-6 .5c-1.07-.09-2.16-.17-3.26-.17-20.39 0-32.52 15.63-34.76 35.54H27.89c-.15 0-.29.09-.44.11a4 4 0 00-.85.2h-.1a3.28 3.28 0 00-.31.2 4.07 4.07 0 00-.79.57l-.13.11s-.09.2-.16.28a4 4 0 00-.45.7c-.05.1-.09.19-.13.29a2.73 2.73 0 00-.1.48 3.19 3.19 0 00-.13.44L3.64 228.63a3.86 3.86 0 001.26 3.27 3.81 3.81 0 002.56 1 4.67 4.67 0 00.83-.08l16.4-3.59 13.94 12.66a3.17 3.17 0 00.6.32 2.89 2.89 0 001.72.48h.33l146.55-9.25a3.83 3.83 0 003.54-4.27z"
                    transform="translate(-.53 -.06)"
                ></path>
                <path
                    fill="#0f213a"
                    fillRule="evenodd"
                    d="M158.54 155.53l-.06-1.76a.55.55 0 00-.37-.59 10.4 10.4 0 00-4.31-.77c-4 .14-6.67 1.69-6.6 5.25.11 5.91 9.42 4.68 9.48 8.33 0 2.39-2.13 3-4.28 3a9.44 9.44 0 01-4.42-1.08c-.3-.14-.44-.15-.43.3v2a.59.59 0 00.34.58 11.79 11.79 0 004.86.9c4.36-.16 7.11-1.88 7-5.79-.22-6.31-9.46-5.28-9.53-8.48 0-2 2.08-2.45 3.73-2.49a9.34 9.34 0 014.12.88c.36.19.48.04.47-.28zm-40.83-1.22a.73.73 0 00-.55-.35h-1.5c-.34 0-.46.14-.45.49l.62 17.71c0 .34.14.47.48.45l2-.06c.34 0 .46-.15.45-.49l-.43-12.32 6.1 9c.21.3.33.4.61.39s.42-.12.6-.43l5.46-9.44.43 12.31c0 .34.14.47.48.46l2-.07c.34 0 .46-.15.45-.49l-.62-17.7c0-.34-.14-.47-.48-.46l-1.5.06a.69.69 0 00-.52.39l-6.41 11zm-55.6 13a12.94 12.94 0 003.77-.94c4.15-1.63 5.68-2.07 7.45-1.57.09 0 .23.08.27 0s0-.22-.11-.32c-2.36-2.94-7-1.51-8.67-.89-4.59 1.66-5.78 2.06-7.73 1.68-.11 0-.21-.07-.26 0s.06.24.15.34a5 5 0 002.21 1.45l.25 7.13c0 .36.09.47.37.46l13.76-.48c.28 0 .35-.09.34-.38l-.07-2c0-.3-.09-.37-.37-.36l-11.18.39-.15-4.49zm-.29-8.65l11.18-.42c.28 0 .35-.09.34-.38l-.07-2c0-.3-.09-.37-.37-.36l-13.77.5c-.28 0-.35.09-.34.46l.27 7.58c1.27-.11 2.58-.77 2.9-1.73zM98.87 166a12.32 12.32 0 003.77-.94c4.15-1.63 5.68-2.07 7.45-1.56.09 0 .23.08.27 0s0-.21-.11-.31c-2.38-2.93-7-1.5-8.7-.88-4.55 1.69-5.77 2.07-7.73 1.69-.12 0-.22-.07-.26 0s.06.24.14.34a5 5 0 002.21 1.45l.25 7.13c0 .36.09.47.37.46l13.76-.48c.28 0 .35-.09.34-.38l-.07-2.05c0-.3-.08-.37-.37-.36L99 170.5l-.14-4.5zm-.29-8.64l11.17-.39c.28 0 .35-.09.34-.38l-.07-2c0-.3-.09-.37-.37-.36l-13.76.49c-.28 0-.35.09-.34.46l.27 7.58a3.32 3.32 0 002.89-1.76zm41-4.19c-.35 0-.45.14-.44.49l.62 17.73c0 .35.12.47.47.46l2.09-.07c.34 0 .45-.15.44-.49l-.62-17.72c0-.35-.14-.47-.47-.46l-2.09.07zm-47.42 18l.07 2c0 .36-.05.41-.4.42l-12.29.41c-.34 0-.46-.1-.47-.46l-.62-17.77c0-.36 0-.44.4-.45l2.16-.07c.34 0 .42.06.43.42l.56 15.41 9.77-.34c.32-.01.41.04.42.4z"
                    transform="translate(-.53 -.06)"
                ></path>
                <path
                    fill="none"
                    stroke="#fffcf8"
                    strokeWidth="6.16"
                    d="M43.91 234.64L31.8 100.37h37.36v16.09a3.85 3.85 0 107.7.08v-16.17h54.74v16.09a3.83 3.83 0 002.21 3.6 3.88 3.88 0 005.49-3.52v-16.17h29.39l14 125.61zm-15-11.52l.29-66.46 6.42 72.68zm-6.88-67l-.79 65.82-9.92 2.16zm51.44-79.38c-2.15 4.25-3.64 11-4.14 15.93h-1.26c.71-5.08 2.63-11.88 5.4-15.93zm25-11.7c14.57 1.61 21.42 13.12 23.46 27.63H77.15c1.88-15.26 7.61-25.17 21.41-27.63zm32.76 27.63h-1.52c-.75-5.64-1.28-11-4.07-15.91 4.14 4.68 4.83 9.47 5.68 15.91zm44.63 3.41a3.84 3.84 0 00-3.82-3.41h-33c-2-20.12-14.16-35.87-34.81-35.87a39.46 39.46 0 00-6 .5c-1.07-.09-2.16-.17-3.26-.17-20.39 0-32.52 15.63-34.76 35.54H27.36c-.15 0-.29.09-.44.11a4 4 0 00-.85.2h-.1a3.28 3.28 0 00-.31.2 4.07 4.07 0 00-.79.57l-.13.11s0 0 0 0-.09.2-.16.28a4 4 0 00-.45.7c-.05.1-.09.19-.13.29a2.73 2.73 0 00-.1.48 3.19 3.19 0 00-.13.44L3.11 228.57a3.86 3.86 0 001.26 3.27 3.81 3.81 0 002.56 1 4.67 4.67 0 00.83-.08l16.4-3.59 13.94 12.66a3.17 3.17 0 00.6.32 2.89 2.89 0 001.72.48h.33l146.55-9.25a3.83 3.83 0 003.54-4.27z"
                ></path>
                <path
                    fill="#0f213a"
                    d="M90.61 179.05L90.74 182.79 93.58 182.7 93.61 183.57 89.71 183.7 89.55 179.09 90.61 179.05z"
                ></path>
                <path
                    fill="#0f213a"
                    d="M98 178.74a2.46 2.46 0 11-2.64 2.53 2.58 2.58 0 012.64-2.53zm.14 4a1.62 1.62 0 001.6-1.65 1.64 1.64 0 10-1.6 1.65z"
                    transform="translate(-.53 -.06)"
                ></path>
                <path
                    fill="#0f213a"
                    d="M106.55 178.5L106.71 183.11 105.8 183.14 103.25 180.38 103.23 180.38 103.33 183.23 102.28 183.26 102.11 178.65 103.03 178.62 105.59 181.39 105.6 181.39 105.5 178.53 106.55 178.5z"
                ></path>
                <path
                    fill="#0f213a"
                    d="M109.58 183.09l-.16-4.61 2.27-.08a2.54 2.54 0 011.92.59 2.3 2.3 0 01.71 1.62 2.35 2.35 0 01-.63 1.73 2.54 2.54 0 01-1.83.68l-2.28.08zm2.2-1a1.33 1.33 0 001-.37 1.43 1.43 0 00.41-1.12 1.47 1.47 0 00-.44-1.05 1.35 1.35 0 00-1.09-.33h-1.17l.1 2.87zM118.51 178a2.46 2.46 0 11.17 4.89 2.46 2.46 0 11-.17-4.89zm.14 4a1.58 1.58 0 10-.11-3.15 1.58 1.58 0 10.11 3.15z"
                    transform="translate(-.53 -.06)"
                ></path>
                <path
                    fill="#0f213a"
                    d="M127.09 177.78L127.25 182.39 126.34 182.42 123.79 179.66 123.78 179.66 123.88 182.51 122.82 182.55 122.66 177.94 123.57 177.9 126.13 180.68 126.14 180.68 126.04 177.82 127.09 177.78z"
                ></path>
                <path
                    fill="none"
                    stroke="#0f213a"
                    strokeWidth="0.5"
                    d="M51.89 7.43L72.05 30.57"
                ></path>
                <path
                    fill="none"
                    stroke="#0f213a"
                    strokeWidth="0.5"
                    d="M96.85 0L97.08 30"
                ></path>
                <path
                    fill="none"
                    stroke="#0f213a"
                    strokeWidth="0.5"
                    d="M142.05 7.43L121.89 30.57"
                ></path>
            </g>
        </svg>
    );
};

EmptyCartIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

EmptyCartIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default EmptyCartIcon;
