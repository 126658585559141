import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ChevronLeft, ChevronRight } from 'react-feather';
import LoadingIndicator from 'ModulesPath/LoadingIndicator';
import { ProductItem } from 'ModulesPath/Catalog/ProductItem';
import { AuthStore, useGlobalOptions } from '@corratech/context-provider';
import { CmsBlockGroup } from '@corratech/cms-display/src/cmsBlock.js';
import './ProductSlider.less';

export const ProductSlider = props => {
    const { type, callbackOnError } = props;
    const options = useGlobalOptions();

    const getFallbackBlockName = () => {
        const siteCode = options.storeConfig.code;
        switch (type) {
            case 'complementary':
                return `${siteCode}-complementary-products`;
                break;
            case 'popular':
                return `${siteCode}-home-most-popular`;
                break;
            case 'trending':
                return `${siteCode}-home-best-seller`;
                break;
        }
    };

    const populateRecommendation = () => {
        if (type === 'trending-gifts') {
            callTrendingGift();
        }
    };

    useEffect(() => {
        populateRecommendation();
    }, []);

    return (
        <>
            <div className={`product-slider`}>
                <CmsBlockGroup
                    identifiers={getFallbackBlockName()}
                    callbackOnError={callbackOnError}
                />
            </div>
        </>
    );
};
ProductSlider.defaultProps = {
    productId: ''
};
