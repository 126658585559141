import React, { useContext, useEffect, useRef } from 'react';
import { CartStore } from "@corratech/context-provider";
import { useObjectCompare } from '../hooks/useObjectCompare';
import { Util } from '@magento/peregrine';
const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();

// This logic needs to be handled in a component because it's use is conditional in the parent component
export const TrackCartState = props => {
    const { dataLayerAction, cartFieldsToObserve } = props;
    const { cartState = {} } = useContext(CartStore);
    const { cartId } = cartState;
    const oldCartIdRef = useRef(storage.getItem('cartId'));
    // Use the initial cart state, available on the page load, in initializing the comparison function
    const { isObjectChanged } = useObjectCompare(cartState.cart, cartFieldsToObserve);

    // Track the initial cart state, if the cart data is available on the page load
    // Track an empty cart if cartId is not set
    useEffect(() => {
        if ((!!cartState.cart.items && cartState.cart.items.length > 0) || !storage.getItem('cartId')) {
            dataLayerAction({ type: 'CART_PAGE', data: cartState });
        }
    }, []);

    // Track new cart data, if the observed fields have changes
    useEffect(() => {
        if (isObjectChanged(cartState.cart, true)) {
            dataLayerAction({ type: 'CART_PAGE', data: cartState });
        }
    }, [cartState]);

    // Track a newly created empty cart
    useEffect(() => {
        if (!!cartId && cartId !== oldCartIdRef.current) {
            dataLayerAction({ type: 'CART_PAGE', data: cartState });
        }
    }, [cartId]);

    return '';
}
