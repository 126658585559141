import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const GiftCardDetails = ({ giftCardOptions }) => {
    const [t] = useTranslation();

    return (
        <div className={'giftcard-info'}>
            <div className={'giftcard-details'}>
                <span className={'giftcard-label'}>
                    {t('Gift Card Recipient')}
                </span>
                <span className={'giftcard-values'}>
                    {`${giftCardOptions.giftcard_recipient_name} - ${giftCardOptions.giftcard_recipient_email}`}
                </span>
            </div>
            <div className={'giftcard-details'}>
                <span className={'giftcard-label'}>
                    {t('Gift Card Sender')}
                </span>
                <span className={'giftcard-values'}>
                    {`${giftCardOptions.giftcard_sender_name} - ${giftCardOptions.giftcard_sender_email}`}
                </span>
            </div>
            {!!giftCardOptions.giftcard_message && (
                <div className={'giftcard-details gift-msg'}>
                    <span className={'giftcard-label gift-msg'}>
                        {t('Gift Card Message')}
                    </span>
                    <span className={'giftcard-values gift-msg'}>
                        {giftCardOptions.giftcard_message}
                    </span>
                </div>
            )}
        </div>
    );
};

GiftCardDetails.propTypes = {
    giftCardOptions: PropTypes.shape({
        giftcard_sender_name: PropTypes.string.isRequired,
        giftcard_sender_email: PropTypes.string.isRequired,
        giftcard_recipient_name: PropTypes.string.isRequired,
        giftcard_recipient_email: PropTypes.string.isRequired,
        giftcard_message: PropTypes.string
    }).isRequired
};

GiftCardDetails.defaultProps = {
    giftcard_message: null
};
