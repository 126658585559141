import React, {
    useContext,
    Fragment,
    useState,
    useEffect,
    useRef
} from 'react';
import { useMutation } from 'react-apollo';
import { CartStore } from '@corratech/context-provider';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import addFreeProductsToCart from './Queries/addFreeProductsToCart.graphql';
import removeItemFromCart from '@corratech/cart/src/queries/removeItemFromCart.graphql';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './FreeGifts.less';
import { useDataLayerAction } from '@corratech/tag-manager';

export const FreeGifts = ({
    ErrorIcon,
    removeItemFromCartGraphql,
    imageParam
}) => {
    const { cartState, dispatch } = useContext(CartStore);
    const [errorMsg, setErrorMsg] = useState(false);
    const giftsRef = useRef(null);
    const [t] = useTranslation();
    const urlParam = `?auto=webp&optimize=high&format=jpg&width=${imageParam.width}`;
    const dataLayerAction = useDataLayerAction();
    const [prodSkus, setProdSkus] = useState({
        add: 0,
        remove: 0
    });

    const [
        removeItem,
        { loading: removeLoading, error: removeError }
    ] = useMutation(removeItemFromCartGraphql, {
        onCompleted: res => {
            const updatedCart = cartState.cart;
            const removedItem = updatedCart.items.filter(
                item => item.product.sku === prodSkus.remove
            )[0];

            dispatch({
                type: 'SET_CART',
                cart: res.removeItemFromCart.cart
            });

            dataLayerAction({
                type: 'REMOVE_TO_CART',
                data: {
                    ...removedItem,
                    ...res.removeItemFromCart,
                    quantityChange: 1
                }
            });
        }
    });

    const [addFreeGifts, { data, loading, error }] = useMutation(
        addFreeProductsToCart,
        {
            onCompleted: res => {
                const updatedCart = cartState.cart;
                const addedItem = res.addFreeProductsToCart.cart.items.filter(
                    item => item.product.sku === prodSkus.add
                )[0];
                updatedCart.items = res.addFreeProductsToCart.cart.items;
                updatedCart.prices = res.addFreeProductsToCart.cart.prices;
                if (res.addFreeProductsToCart.cart.free_items) {
                    updatedCart.free_items =
                        res.addFreeProductsToCart.cart.free_items;
                }
                if (res.addFreeProductsToCart.cart.total_quantity)
                    updatedCart.total_quantity =
                        res.addFreeProductsToCart.cart.total_quantity;
                updatedCart.is_virtual =
                    res.addFreeProductsToCart.cart.is_virtual;
                dispatch({
                    type: 'SET_CART',
                    cart: res.addFreeProductsToCart.cart
                });

                dataLayerAction({
                    type: 'ADD_TO_CART',
                    data: {
                        ...addedItem,
                        quantityChange: 1,
                        cart: updatedCart,
                        category: ''
                    }
                });
            }
        }
    );

    useEffect(() => {
        if (error) setErrorMsg(error);
        if (removeError) setErrorMsg(error);
        if (giftsRef && giftsRef.current)
            giftsRef.current.scrollIntoView({ behavior: 'smooth' });
        const timer = setTimeout(() => {
            setErrorMsg(false);
        }, 10000);
        return () => clearTimeout(timer);
    }, [error, removeError]);

    const getItemId = sku => {
        let addedItemId = '';
        cartState.cart.items.map(item => {
            if (sku == item.product.sku) {
                addedItemId = item.id;
                return addedItemId;
            }
        });
        return addedItemId;
    };

    const uniqueGiftRuleCollection =
        cartState.cart.free_items &&
        cartState.cart.free_items.free_gifts &&
        cartState.cart.free_items.free_gifts.length > 0
            ? [
                  ...new Map(
                      cartState.cart.free_items.free_gifts.map(gift => [
                          gift.rule_id,
                          gift
                      ])
                  ).values()
              ]
            : [];

    const allowedGiftCount =
        uniqueGiftRuleCollection.length > 0
            ? uniqueGiftRuleCollection.reduce(function(prev, current) {
                  return prev + current.allowed_qty;
              }, 0)
            : 0;

    return (
        <Fragment>
            {cartState.cart.applied_coupons &&
            cartState.cart.applied_coupons[0] &&
            cartState.cart.free_items &&
            cartState.cart.free_items.free_gifts.length > 0 ? (
                <div className={'free-gift'}>
                    <div className={'gift-title'}>
                        {`${t('CODE')} ${
                            cartState.cart.applied_coupons[0].code
                        }${`:`} ${t('SELECT')} ${allowedGiftCount} ${t(
                            `FREE`
                        )} ${allowedGiftCount === 1 ? `GIFT` : `GIFTS`} `}
                    </div>
                    <div className={'gifts-error error-msg'} ref={giftsRef}>
                        {errorMsg && errorMsg.graphQLErrors && (
                            <Alert variant={'danger'}>
                                <span className={'message'}>
                                    {ErrorIcon}
                                    {errorMsg.graphQLErrors.map(
                                        ({ message }, i) => (
                                            <p>{message}</p>
                                        )
                                    )}
                                </span>
                            </Alert>
                        )}
                    </div>
                    <div className={'gift-items'}>
                        <PerfectScrollbar options={{ suppressScrollY: true }}>
                            <ul
                                className={'gift-items-list'}
                                style={{
                                    gridTemplateColumns: `repeat(${cartState.cart.free_items.free_gifts.length}, 115px)`
                                }}
                            >
                                {cartState.cart.free_items.free_gifts.map(
                                    (item, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className={
                                                    item.is_added === 1
                                                        ? 'free-item added'
                                                        : 'free-item'
                                                }
                                            >
                                                {item.is_added === 1 && (
                                                    <button
                                                        className={'remove-btn'}
                                                        type="button"
                                                        disabled={removeLoading}
                                                        onClick={() => {
                                                            setProdSkus({
                                                                ...prodSkus,
                                                                ...{
                                                                    remove:
                                                                        item.sku
                                                                }
                                                            });
                                                            removeItem({
                                                                variables: {
                                                                    cartId:
                                                                        cartState.cartId,
                                                                    itemId: parseInt(
                                                                        getItemId(
                                                                            item.sku
                                                                        )
                                                                    )
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        {t('Remove')}
                                                    </button>
                                                )}
                                                <img
                                                    src={`${item.img}${urlParam}`}
                                                    alt={item.name}
                                                    title={item.name}
                                                    onClick={() => {
                                                        if (
                                                            item.is_added ===
                                                                1 &&
                                                            !removeLoading
                                                        ) {
                                                            setProdSkus({
                                                                ...prodSkus,
                                                                ...{
                                                                    remove:
                                                                        item.sku
                                                                }
                                                            });
                                                            removeItem({
                                                                variables: {
                                                                    cartId:
                                                                        cartState.cartId,
                                                                    itemId: parseInt(
                                                                        getItemId(
                                                                            item.sku
                                                                        )
                                                                    )
                                                                }
                                                            });
                                                        }
                                                    }}
                                                />
                                                <span
                                                    className={'gift-item-name'}
                                                >
                                                    {item.name}
                                                </span>
                                                <button
                                                    type="button"
                                                    className={
                                                        item.is_added === 1
                                                            ? 'btn btn-primary btn-active'
                                                            : 'btn btn-primary'
                                                    }
                                                    disabled={
                                                        loading ||
                                                        removeLoading ||
                                                        item.max_qty <= 0
                                                            ? item.is_added ===
                                                              1
                                                                ? loading ||
                                                                  removeLoading
                                                                    ? true
                                                                    : false
                                                                : true
                                                            : false
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            item.is_added === 0
                                                        ) {
                                                            setProdSkus({
                                                                ...prodSkus,
                                                                ...{
                                                                    add:
                                                                        item.sku
                                                                }
                                                            });
                                                            addFreeGifts({
                                                                variables: {
                                                                    input: {
                                                                        cart_id:
                                                                            cartState.cartId,
                                                                        cart_items: [
                                                                            {
                                                                                data: {
                                                                                    quantity: 1,
                                                                                    sku:
                                                                                        item.sku,
                                                                                    max_qty:
                                                                                        item.max_qty,
                                                                                    rule_id:
                                                                                        item.rule_id
                                                                                }
                                                                            }
                                                                        ]
                                                                    }
                                                                }
                                                            });
                                                        } else {
                                                            setProdSkus({
                                                                ...prodSkus,
                                                                ...{
                                                                    remove:
                                                                        item.sku
                                                                }
                                                            });
                                                            removeItem({
                                                                variables: {
                                                                    cartId:
                                                                        cartState.cartId,
                                                                    itemId: parseInt(
                                                                        getItemId(
                                                                            item.sku
                                                                        )
                                                                    )
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {item.is_added === 1
                                                        ? t('ADDED')
                                                        : t('ADD')}
                                                </button>
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </PerfectScrollbar>
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};

FreeGifts.defaultProps = {
    removeItemFromCartGraphql: removeItemFromCart,
    imageParam: { width: 67 }
};
